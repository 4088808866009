import { FC, useMemo } from 'react';
import { Option } from '../../../Option';
import ActionPreview from '../../ActionPreview';
import ActionBaseProps from '../ActionBaseProps';

//                _   _               _
//      /\       | | (_)             (_)
//     /  \   ___| |_ _  ___  _ __    _ ___
//    / /\ \ / __| __| |/ _ \| '_ \  | / __|
//   / ____ \ (__| |_| | (_) | | | | | \__ \
//  /_/___ \_\___|\__|_|\___/|_| |_| |_|___/          _
//  |  __ \                              | |         | |
//  | |  | | ___ _ __  _ __ ___  ___ __ _| |_ ___  __| |
//  | |  | |/ _ \ '_ \| '__/ _ \/ __/ _` | __/ _ \/ _` |
//  | |__| |  __/ |_) | | |  __/ (_| (_| | ||  __/ (_| |
//  |_____/ \___| .__/|_|  \___|\___\__,_|\__\___|\__,_|
//              | |
//              |_|

const MultiSelectListPreview: FC<ActionBaseProps<Option<string, string>[]>> = (props) => {
  const { response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const answer = useMemo(() => {
    if (!response) {
      return '-';
    }

    return response.map((x) => x.text);
  }, [response]);
  return <ActionPreview data-cy="multi-select-list-preview" question={title} description={previewDescription} answer={answer} />;
};

export default MultiSelectListPreview;
